import {
  mq_breakpointIsMobile,
  mq_breakpointIsDesktop,
  mq_isTouch,
} from '@/injectionSymbols';

export function useIsDesktopView() {
  const isDesktop = inject(mq_breakpointIsDesktop);
  const isMobile = inject(mq_breakpointIsMobile);
  const isTouch = inject(mq_isTouch);

  const isDesktopView = computed(() =>
    isTouch.value ? !isMobile.value : isDesktop.value,
  );

  return isDesktopView;
}
